// TODO: the below is from demo_accruals - i have already begun modification for live app...
//
//
import { Typography, Grid, FormControl, MenuItem, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useState, useMemo, useEffect } from "react";
import * as Constants from "../../../Constants";
import DirectRequest from "../../../API/requests/DirectRequest";
import { setAlertNotificationArgs } from "../../../store/alertsNotificationSlice";
import iso8601Timestamp from "../../../helpers/iso8601Timestamp";

const ReportsSelect = ({reports, companyUuid}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);
  const isAccrualsUser = useSelector(state => state.role.isAccrualsUser);
  const dashboardUuid = useSelector(state => state.dashboard.uuid);
  const apps = useSelector(state => companyUuid ? state.appsSysAdmin.list : state.apps.filteredList)// need to pass thisDevApp because i need the tags for "view_config__hide_alerts"
  const thisDevApp = useMemo(() => {
    return apps.find(app => app.uuid === dashboardUuid);
  }, [apps])

  const user = useSelector(state => state.role.name);
  const isInternal = useSelector(state => state.role.isInternal);

  const handleClick = (erpReportUuid) => {
    console.log("erpReportUuid", erpReportUuid);
    // console.log("reports", reports);
    // console.log("thisReport", reports.find(report => report.uuid === erpReportUuid));
    if (!isInternal) {
      const { reportName, entryPeriod, entryPeriodName, developerAppUuid } = reports.find(report => report.uuid === erpReportUuid);
      const tempNotificationArgs = {
        url: Constants.SERVER_SEND_EMAIL,
        method: "POST",
        body: JSON.stringify({
          "body": `<strong>${user}</strong> viewed the ${reportName} report (${erpReportUuid}) for entryPeriod ${entryPeriod} (${entryPeriodName}) on ${window.location.origin}<br/><br/>dapp: ${developerAppUuid}<br/><br/>`,
          "subject": `${user} viewed the ${reportName} report for entryPeriod ${entryPeriod} (${window.location.origin} - ${iso8601Timestamp()})`,
          "from": "noreply@wiselayer.com",
          "toEmail": Constants.notificationList.join(","),
          "sendHTML": true
        })
      }
      dispatch(setAlertNotificationArgs(tempNotificationArgs));
    }
    if (isAccrualsUser) {
      // navigate to DashboardReportGetter route in isAccrualsUser router
      const dashboardReportState = {
        erpReportUuid: erpReportUuid
      }
      localStorage.setItem("dashboardReportState", JSON.stringify(dashboardReportState))
      window.open(`${window.location.origin}/get_report`, "_blank")
    } else {
      // navigate to new ReportRedirect route in Legacy router
      const dashboardReportState = {
        thisDevApp: thisDevApp,
        uuid: dashboardUuid,
        viewReport: true,
        erpReportUuid: erpReportUuid
      }
      if (companyUuid) {
        dashboardReportState["companyUuid"] = companyUuid;
      }
      localStorage.setItem("dashboardReportState", JSON.stringify(dashboardReportState));
      window.open(`${window.location.origin}/get_report`, "_blank")
    }
  }

  return (
      <FormControl sx={{ width: "100%" }}>
        <TextField
          select
          label={"Period"}
          value={value}
          size="small"
          disabled={!dashboardUuid}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  },
                  '& .MuiMenuItem-root.Mui-selected': {
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }
                }
              }
            }
          }}
        >
          {reports.length > 0 && reports.map((report, index) => (
            <MenuItem 
              value={report.uuid} 
              key={`${report.uuid}_${index}`} 
              sx={{
                '&.Mui-focusVisible': {
                  backgroundColor: 'transparent'
                }
              }}
              onClick={(e) => handleClick(report.uuid)}
            >
              <strong>{report.entryPeriodName}</strong>
            </MenuItem>
          ))}
          {reports.length === 0 && (
            <MenuItem
              value={null}
              sx={{
                '&.Mui-focusVisible': {
                  backgroundColor: 'transparent'
                }
              }}
              onClick={() => {}}
              disabled
            >
              No previous reports
            </MenuItem>
          )}
        </TextField>
      </FormControl>
  )
}


const containerStyle = {
	padding: '16px',
	marginRight: '16px',
	// marginBottom: '8px',
	backgroundColor: 'white',
	boxShadow: '0 1px 8px rgba(0,0,0,0.12)',
	borderRadius: '10px',
	display: 'inline-block',
	verticalAlign: 'top',
	width: "100%",
  height: "100%",
  display: "flex"
	// minWidth: '200px'  // Adjust based on your needs
};

const titleStyle = { fontSize: "16px", fontWeight: 500 }

export const FDReports = (props) => {
  // get list of erp reports
  const reportName = useSelector(state => state.dashboard.reportName);
  const companyUuid = useSelector(state => state.dashboard.companyUuid);
  const [listERPReportsArgs, setListERPReportsArgs] = useState(null);
  const [reportsList, setReportsList] = useState([]);

  useEffect(() => {
    if (reportName) {
      console.log("reportName", reportName)
      const tempListArgs = {
        url: companyUuid ? Constants.SERVER_SYSADMIN_LIST_ERP_REPORTS_URL + companyUuid : Constants.SERVER_LIST_ERP_REPORTS_URL,
        method: "POST",
        body: JSON.stringify({
          reportName: reportName,
          includeReportData: false
        })
      }
      setListERPReportsArgs(tempListArgs);
    }
  }, [reportName, companyUuid])

  const handleERPReports = (res) => {
    console.log("FDReports res", res);
    // if none - disable
    // otherwise:
    // - sort by 'reportTimestamp'
    // - display by entryPeriodName
    // - onClick needs to check if isAccrualsUser - send to DashboardGetter route - otherwise send to I think new ReportRedirect which will handle companyUuid or not cases
    if (res.file && res.file.length > 0) {
      // Log original UUIDs
      // console.log("Original UUIDs:", res.file.map(r => r.uuid));
      
      const reports = res.file.map(report => ({...report}));
      
      // Log UUIDs before sort
      // console.log("UUIDs before sort:", reports.map(r => r.uuid));
      
      reports.sort((reportA, reportB) => {
        return new Date(reportB.reportTimestamp) - new Date(reportA.reportTimestamp);
      });
      
      // Log UUIDs after sort
      // console.log("UUIDs after sort:", reports.map(r => r.uuid));
      
      console.log("FDReports reports post sort", reports);
      setReportsList(reports);
    } else {
      // do nothing
    }
  }
  return (
    <>
      <DirectRequest
        requestArgs={listERPReportsArgs}
        afterProcess={handleERPReports}
        handleError={err => console.log("error getting reports", err)}
        handleCatchError={err => console.log("client error getting reports", err)}
      />
      <div style={containerStyle}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Typography variant="p" style={titleStyle}>{props.title}</Typography>
          </Grid>
          <Grid item xs={9}>
            <ReportsSelect reports={reportsList} companyUuid={companyUuid} />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default FDReports;