import { useState, useEffect} from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RedirectLoader } from './redirectUtils';
import { Grid, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const ReportRedirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("dashboardReportState")) {
      /* thisDevApp: moduleApp,
        uuid: moduleApp.uuid,
        // viewApp: true,// this is passed by DashboardGetter - i will modify Dashboard useEffect to have clause for viewReport
        customerSubscriptionUuid: moduleAppSubscriptionUuid,
        moduleApp: true,// is kept as state in Dashboard - fed to FDTopBar and the like to control buttons/cancelAction - required for "module" views
        viewReport: true,
        erpReportUuid: dashboardReportState.erpReportUuid */
      const dashboardReportState = JSON.parse(localStorage.getItem("dashboardReportState"));
      navigate(`/dashboard/${dashboardReportState.uuid}`, { state: dashboardReportState });
      localStorage.removeItem("dashboardReportState");
    } else {
      navigate("/")
    }
  }, [])

  const cancelAction = () => {
    window.close()
  }

  return (
    <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={4}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Grid item>
              <Button
                onClick={cancelAction}
                size="small"
                variant="contained"
                startIcon={<CloseIcon />}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RedirectLoader/>
        </Grid>
      </Grid>
  )
}

export default ReportRedirect;