import { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, Modal, Box, Typography, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as Constants from "../../../../../../Constants";
import { getEntriesToExclude } from "./helpers/getEntriesToExclude";
import DirectRequest from "../../../../../../API/requests/DirectRequest";
import { dashboardConfigsSelectors } from "../../../../../../store/dashboardConfigsSlice";
import { setIsReport } from "../../../../../../store/dashboardSlice";
import GradingIcon from '@mui/icons-material/Grading';
import CheckIcon from "@mui/icons-material/Check";
import { setAlertNotificationArgs } from "../../../../../../store/alertsNotificationSlice";
import iso8601Timestamp from "../../../../../../helpers/iso8601Timestamp";

const ConfirmSaveERPReport = ({companyUuid, dashboardUuid, handleSave, handleToggle, loading, setLoading}) => {
  const subscribedApps = useSelector(state => state.appsSubscriptions.list);
  const [subscriptionsArgs, setSubscriptiosnArgs] = useState(null);
  const [getDataArgs, setGetDataArgs] = useState(null);
  const handleConfirm = () => {
    setLoading(true);
    if (companyUuid) {
      console.log("sysadmin saving report - getting company subscriptions")
      // get subscriptions - then getData
      const tempSubsArgs = { url: Constants.SERVER_SYSADMIN_CUS_SUBBED_APPS_URL + companyUuid }
      setSubscriptiosnArgs(tempSubsArgs)
    } else {
      // getData
      console.log("user saving report - getting app data")
      const subscriptionUuid = subscribedApps.find((sub) => sub.developerAppUuid === dashboardUuid).uuid
      const tempArgs = {
        url: Constants.SERVER_CUS_SUBBED_APP_URL,
        method: "POST",
        body: JSON.stringify({
          customerSubscriptionUuid: subscriptionUuid,
        }),
      };
      setGetDataArgs(tempArgs);
    }
  }
  const handleSubscriptions = (res) => {
    console.log("sysadmin saving report - getting app data")
    const subscriptionUuid = res.find((sub) => sub.developerAppUuid === dashboardUuid).uuid
    const tempArgs = {
      url: Constants.SERVER_SYSADMIN_CUS_SUBBED_APP_URL + companyUuid,
      method: "POST",
      body: JSON.stringify({
        customerSubscriptionUuid: subscriptionUuid,
      }),
    };
    setGetDataArgs(tempArgs);
  }
  const handleData = (res) => {
    // you have the data - what shape is it
    console.log("app data", res)
    handleSave(res);
  }
  return (
    <>
      <DirectRequest
        //sysAdminSubscriptions
        requestArgs={subscriptionsArgs}
        afterProcess={handleSubscriptions}
      />
      <DirectRequest
        //getDataArgs
        requestArgs={getDataArgs}
        afterProcess={handleData}
        handleError={(err) => console.log("err getting app data", err)}// TODO: error chip?
        handleCatchError={(err) => console.log("catch err getting app data")}// TODO: error chip?
      />
      {/* Grid with message and 2 buttons */}
      <Grid container spacing={1}>
        <Grid item xs={12} container justifyContent={"space-between"} alignItems={"center"}>
          <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>Save ERP Report</Typography>
          <IconButton onClick={handleToggle} sx={{ padding: "4px" }}>
            <CloseIcon/>
          </IconButton>
        </Grid>
        <Grid item xs={12} container alignItems={"center"}>
          <Typography>This action is permenant and cannot be undone - confirm when you're ready.</Typography>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} container justifyContent={"center"} alignItems={"center"}>
          <Button
            size={"small"}
            onClick={handleConfirm}
            variant={"contained"}
            startIcon={loading ? <CircularProgress size={"1rem"} /> : <CheckIcon/>}
            disabled={loading}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export const SaveERPReportButton = ({ preview, companyUuid }) => {
  const dispatch = useDispatch();
  const isInternal = useSelector((state) => state.role.isInternal);
	const user = useSelector((state) => state.role.name);

  const dashboardUuid = useSelector(state => state.dashboard.uuid);
  const isReport = useSelector(state => state.dashboard.isReport);
  const reportName = useSelector(state => state.dashboard.reportName);
  const entryPeriod = useSelector(state => state.dashboard.entryPeriod);
  const entryPeriodName = useSelector(state => state.dashboard.entryPeriodName);
  const dashboardJson = useSelector((state) => state.dashboard.dashboardJson);
  const tables = useSelector((state) => state.dashboard.tables);
  const displayConfigs = useSelector((state) => dashboardConfigsSelectors.selectAll(state));
  const showButton = useMemo(() => {
    // console.log("showButton - dashboardJson", dashboardJson);
    let show = false;
    Object.values(dashboardJson.components).forEach((component) => {
      if (component.type === "table" && component.createEntryConfig && !isReport) {// don't show this button if report
        show = true;
      }
    })
    return show;
  }, [dashboardJson, isReport])
  
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveERPReportArgs, setSaveERPReportArgs] = useState(null);

  const handleToggle = () => {
    if (loading) return;
    setOpen((prevOpen) => !prevOpen);
  }

  const handleSave = (appData) => {
    /* 
    developerAppUuid, - dashboardUuid
    reportName, // createEntryConfig (any works - save like )
    entryPeriod, 
    entryPeriodName (script will give this to you inside a createEntryConfig I think), 
    appData (which is the dashboard and tableMap containing all of the appData, just as you get it from the server), 
    erpEntryExcludeList which you're already familiar with, and an 
    replaceExisting ++optional++ which will overwrite a current report if it exists (if this is false, saving two reports of the same name in the same period will throw an exception)
    

    */
    const tempArgs = {
      url: companyUuid ? Constants.SERVER_SYSADDMIN_SAVE_ERP_REPORT_URL + companyUuid : Constants.SERVER_SAVE_ERP_REPORT_URL,
      method: "POST",
      body: JSON.stringify({
        developerAppUuid: dashboardUuid,
        reportName: reportName,
        entryPeriod: entryPeriod,
        entryPeriodName: entryPeriodName,
        appData: appData,
        erpEntryExcludeList: getEntriesToExclude(displayConfigs, dashboardJson, tables),// is this param name right?
        replaceExisting: false
        // replaceExisting: !!companyUuid --> do we want this default for sysadmin or on a toggle for sysadmin or?
        // you need to get app data - if companyUuid - you need to get their subscriptions and then get 
      })
    }
    console.log("save erp report args", tempArgs);
    console.log("save erp report body", JSON.parse(tempArgs.body));
    setSaveERPReportArgs(tempArgs);
  }
  const handleERPReportSave = (res) => {
    // TODO: what do we do?
    console.log("report saved!", res);
    if (!isInternal) {
      const body = JSON.parse(saveERPReportArgs.body);
      const args = Object.entries(body);
      const formattedArgs = args
        .filter(([key]) => key !== "appData") // Exclude "appData" from the entries
        .map(([key, value]) => {
          // Format the value for readability
          const formattedValue = typeof value === 'object' ? JSON.stringify(value, null, 2) : value;
          return `${key}: ${formattedValue}`;
        });
      const argsInBody = formattedArgs.join('<br/>');
      const notificationArgs = {
        url: Constants.SERVER_SEND_EMAIL,
        method: "POST",
        body: JSON.stringify({
          "body": `${user} saved ${reportName} report for ${entryPeriod}<br/><br/><br/>request body:<br/><br/>${argsInBody}`,
          "subject": `${user} saved ${reportName} report for ${entryPeriod} (${window.location.origin} - ${iso8601Timestamp()})`,
          "from": "noreply@wiselayer.com",
          "toEmail": Constants.notificationList.join(","),
          "sendHTML": true
        })
      }
      dispatch(setAlertNotificationArgs(notificationArgs))
    }
    dispatch(setIsReport(true))
    setOpen(false);
    setLoading(false);
    // send notification
    
  }
  return showButton ? (
    <>
      <DirectRequest
        requestArgs={saveERPReportArgs}
        afterProcess={handleERPReportSave}
        // TODO: error handling
      />
      <Grid item>
        <Button
          size="small"
          onClick={handleToggle}
          disabled={preview}
          variant={"contained"}
          startIcon={<GradingIcon/>}
        >
          Save Report
        </Button>
      </Grid>
      {/* Modal component */}
      <Modal
          open={open}
          onClose={handleToggle}
          aria-labelledby="create-entry-modal-title"
          aria-describedby="create-entry-modal-description"
        >
          {/* Style for modal content */}
          <Box 
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 800, // You can adjust the size as needed
              maxHeight: '100vh', // Limit the maximum height to 90% of the viewport height
              overflowY: 'auto', // Enable vertical scrolling if content overflows
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 3,
              // borderRadius: 1, // Optional, for a rounded corner effect
            }}
          >
            <ConfirmSaveERPReport
              companyUuid={companyUuid}
              handleToggle={handleToggle}
              handleSave={handleSave}
              dashboardUuid={dashboardUuid}
              loading={loading}
              setLoading={setLoading}
            />
          </Box>
        </Modal>
    </>
  ) : null;
}

export default SaveERPReportButton;