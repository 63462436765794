import { config_options } from "../../../../../../../store/dashboardConfigsSlice";

export const getEntriesToExclude = (displayConfigs, dashboardJson, tables) => {
  const entriesToExclude = [];
   // get all displayConfigs for this app
   console.log("generateDownloadCSVArgs - displayConfigs", displayConfigs)
   displayConfigs.forEach((displayConfig) => {
    // get each displayConfig
    // find it's objectName
    const objectName = displayConfig.objectName;
    const sourceTable = displayConfig.sourceTable;
    // get component definition for objectName
    const component = dashboardJson.components[objectName];
    // get statusTrackerConfig
    const statusTrackerConfig = component.statusTrackerConfig;
    // ok, now loop through each displayConfigTableRows
    displayConfig.displayConfigTableRows.forEach((displayConfigTableRow) => {
      // if displayConfigTableRow.create_entry
      const createdEntry = displayConfigTableRow.displayConfigOptions[config_options.create_entry]?.optionValue;
      if (createdEntry) {
        //  get table (displayConfig.sourceTable or objectName.sourceTable - they are the same)  
        const table = tables[sourceTable];
        const columns = table.data[0];
        const rows = table.data.slice(1);
        let excluded = false;
        rows.forEach((row) => {
          if (excluded) return;
          // find matching table row
          const matchingTableRow = Object.entries(displayConfigTableRow.primaryKeyValueMap).every(([primaryKey, value]) => row[columns.indexOf(primaryKey)] === value);
          if (matchingTableRow) {
            // check statusTrackerConfig.overrideColName of row -> if truthy and row.create_entry -> add row.create_entry to entriesToExclude
            const overridden = row[columns.indexOf(statusTrackerConfig.overrideColName)];
            if (overridden) {
              console.log(`overridden row found for created entry ${createdEntry}`, row);
              excluded = true;
              entriesToExclude.push(createdEntry);
            }
            const hidden = !!displayConfigTableRow.displayConfigOptions[config_options.hide_row]?.optionValue;
            if (!overridden && hidden) {
              console.log(`hidden row found for created entry ${createdEntry}`, row);
              excluded = true;
              entriesToExclude.push(createdEntry);
            }
          }
        })  
      }
    })
   })
   // great, now we have entries to exclude
   return entriesToExclude;
}